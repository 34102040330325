import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import acidCat from "../images/icons/icon_acidcat.png"
import HalfBlock from "../components/HalfBlock"
import { darken } from "polished"
import theme from "../styles/theme"
import { BsArrowRight } from "react-icons/bs"
import { Button } from "../styles/ui"

const PageNotFound = () => {
  return (
    <Layout>
      <HalfBlock
        background={darken(0.02, theme.bg)}
        imgSrc={acidCat}
        title="You broke the website!"
        titleLarger="We offend greatly."
        text="Actually, the page you requested cannot be found ;("
        cta={
          <Link to="/">
            <Button>
              Go back home! <BsArrowRight />
            </Button>
          </Link>
        }
      />
    </Layout>
  )
}

export default PageNotFound
